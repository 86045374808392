.center {
    text-align: center;
}

/*#root {*/
    /*height: -webkit-fill-available;*/
/*}*/

/*.appRoot {*/
    /*height: -webkit-fill-available;*/
/*}*/

.step-header {
    width: 100%;
}

.divider {
    width: 70% !important;
    margin: 2rem 0rem !important;
}

.p-divider {
    width: 35% !important;
    margin: 1rem auto !important;
    text-align: center;
    background-color: #f15a29 !important;
}

.m-divider {
    width: 70% !important;
    margin: 2rem auto !important;
}

.step-header h4{
    width: calc(100% - 2rem);
    display: inline-block;
    text-align: center;
    margin: 0px;
}

.right-grid {
    padding: 3rem 2rem;
}

p {
    font-weight: 600 !important;
}

.orange-section {
    /*background-color: #e3e4e5;*/
    padding: 1rem 0rem 1rem 0rem;
    width: 100%;
}

.zebyl-logo {
    width: 65% !important;
    /*margin: 2rem auto 0rem;*/
    margin: 1rem auto;
}

.company-logo {
    width: 20% !important;
    /*margin: 2rem auto 0rem;*/
    margin: 1rem auto;
}



.introduction-logo {
    width: 100% !important;
}

.introduction-section {
    /*padding: 3rem 6.5rem 0rem;*/
    padding: 0rem 4rem 0rem;
    width: 100%;
}

.introduction-section .introduction-orange {
    color: white !important;
    background-color: #ff7f27;
    border-radius: 0.8rem !important;
    padding: 1.5rem !important;
}

.introduction-section .introduction-extra {
    padding: 0rem 1.5rem !important;
}

.introduction-section p{
    /*text-align: center;*/
    text-align: left;
    font-size: 1.1rem !important;
    color: #696a6d !important;
    font-family: Segoe UI,Helvetica Neue,Arial,Helvetica,sans-serif !important;
}

.left-grid {
    /*border: 2px solid #f05a28 !important;*/
    /*background-color: #f7f9fa !important;*/
    background-color: #f7f9fa !important;
}

.back-icon {
    width: 10px !important;
    cursor: pointer;
}

.time-slot {
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid rgba(240, 90, 40, 0.39);
    cursor: pointer;
    border-radius: 0.3rem;
}

.time-slot:hover {
    border-color: #f15a29;
}

.time-slot:hover .header{
    /*color: #f15a29;*/
    color: #f15a29;
}


.successResponse  {
    min-height: 0;
    background: #fff;
    border: none;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    padding: 20px 15px;
}

.introductoryCard {
    background: inherit !important;
    box-shadow: none !important;
    margin: auto !important;
    width: 350px !important;
}


.introductoryCard .circular-image{
    /*border: 0.1rem solid white;*/
    border-radius: 50%;
}

.introductoryCard .header {

    font-size: 1.1rem !important;
    color: #f15a29 !important;
    /*color: #f15a29 !important;*/
    font-family: Segoe UI Black,Helvetica Neue,Arial,Helvetica,sans-serif !important;
    font-weight: 800 !important;

    /*-webkit-text-stroke-width: medium;*/
}

.introductoryCard .meta, .introductoryCard .description {
    /*color: #5e5c5c !important;*/
    color: #291f17 !important;
    font-weight: 700 !important;
    font-size: 1.1rem !important;
    /*text-align: center;*/
}

.introductoryCard .meta span {
    display: inline-block;
}

table {
    border: white !important;
}

table thead th {
    background-color: white !important;
    border: white !important;
}

table thead th i{
    opacity: 1 !important;
    cursor: pointer !important;
}

table td {
    border: white !important;
}

.ui.table {
    font-size: 1.3rem !important;
}

table td:not(.disabled) {
    font-weight: 700;
}

.user-detail-form {
    margin-right: auto;
    margin-left: auto;
    width: 75% !important;
    max-width: 800 !important;
}

.zebylbot-form {
    width: 25rem !important;
    max-width: 800 !important;
}

@media only screen and (max-width: 600px) { /* Pixel 2 */
    table {
        width: 22rem !important;

    }

    .user-detail-form {
        width: 100% !important;
    }

    .zebylbot-form {
        width: 80% !important;
    }

    .ui.table {
        font-size: 1rem !important;
    }

    .introduction-section {
        /*padding: 3rem 6.5rem 0rem;*/
        padding: 0rem 2rem 0rem;
        width: 100%;
    }
}

.ui.segment label {
    font-weight: 700;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

